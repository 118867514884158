import { FiArrowRight } from "react-icons/fi";

export default function LinkWithDivider(props: {
	title: string;
	isFirstItemOfGroup?: boolean;
	href?: string;
	borderX?: boolean;
}) {
	const { title, isFirstItemOfGroup, href, borderX } = props;

	return (
		<div
			className={`border-y border-black bg-white ${isFirstItemOfGroup ? "" : "!border-t-0"} px-4 ${
				borderX ? "border-x" : ""
			}`}
		>
			{!href && (
				<div className="flex w-full items-center justify-between py-4 text-left text-gray-800 antialiased">
					<span>{title}</span>
				</div>
			)}
			{href && (
				<a href={href} className="flex w-full items-center justify-between py-4 text-left text-gray-800 antialiased">
					<span>{title}</span>
					<FiArrowRight size={"20px"} />
				</a>
			)}
		</div>
	);
}
